import React, { useState, useEffect } from "react";
import { Link } from "gatsby";
import requests from "../../../services/requests";
import ClipLoader from "react-spinners/ClipLoader";
import WeekRow from "../../../components/WeekRow";

const WeeklySchedule = filterInformation => {
    let [load, setLoad] = useState(false);
    let [list, setList] = useState([]);

    const listData = () => {
        setLoad(true);
        requests
            .week({
                filters: filterInformation.filterInformation,
            })
            .then(data => {
                setList(data.weeks[0].days);

                setLoad(false);
            });
    };

    useEffect(() => {
        listData();
    }, [filterInformation.filterInformation]);

    return (
        <>
            {load ? (
                <div className="flex flex-col items-center">
                    <ClipLoader size={20} loading={load} />
                </div>
            ) : (
                <>
                    <div className="flex md-fit xs:flex-wrap lg:flex-no-wrap">
                        <WeekRow list={list} />
                        {/*{list.map((day, dayKey) => {
                            return (
                                <Kanban
                                    key={dayKey}
                                    title={day.fullFormatted}
                                    className="xs:border-r-0 lg:border-r border-C2 xs:w-full md:w-1/3 lg:w-1/6"
                                >
                                  {day.events.map((data, eventKey) => {
                                        return (
                                            <Link key={eventKey} to={`/processos/detalhes/${data.process.identifier}`} state={{ prevPath: window.location.pathname }}>
                                                <CardSchedule
                                                    code={data.process.code}
                                                    category={data.description}
                                                >
                                                </CardSchedule>
                                            </Link>
                                        )
                                    })}
                                </Kanban>
                            )
                        })}*/}
                    </div>
                    {list.length === 0 && (
                        <div className="flex flex-col items-center">Sem processos processos para essa semana</div>
                    )}
                    <div className="flex justify-end">
                        <Link to={`/agenda`}>
                            <p className="text-roxo_oficial cursor-pointer">ver agenda completa</p>
                        </Link>
                    </div>
                </>
            )}
        </>
    );
};
export default WeeklySchedule;
